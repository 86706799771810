import React from 'react';
import { Card, Col, Row, Table, Container, Stack } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { BrowserRouter, Route,  Switch, Link } from 'react-router-dom';

const Sitemap = import('../pages/other/Sitemap/');
const Error404 = import('../pages/error/Error404');


const routes = [
    {
      path: "/Sitemap",
      component: Sitemap
    },
    {
        path: "/Error404",
        component: Error404
    }
  ];

const Routes_test = () => {

        

    return (
        <>
        <BrowserRouter basename="">
      

            <Route path="/Sitemap">
            <div>Sitemap</div>
            </Route>
            <Route path="/Error404" >
            <div>404</div>
            </Route>
            <Route status={404}>
            </Route>
        </BrowserRouter>

        </>
    );
};

export default Routes_test;


